import dynamic from 'next/dynamic'
import { TAuthUser } from '@microfrontends/react-components'
import {
	TDeviceViewPort,
	TSSRResultData,
	getServerSideProps
} from '../ssr/home'
import { ELanguage } from '@microfrontends/app-shell-v2'
import { Suspense } from 'react'

const Layout = dynamic(() => import('../components/Layouts/MainLayout'))
const ServerHomePage = dynamic(() => import('../components/Pages/Home/server'))
const ClientHomePage = dynamic(() => import('../components/Pages/Home/client'))

interface IProps {
	langCode: ELanguage
	user: TAuthUser
	userIP: string
	resultAll: Partial<TSSRResultData>
	isRobotRequesting: boolean
	viewport: TDeviceViewPort
}

function Home(props: IProps) {
	const isBot = props.isRobotRequesting ?? false
	return (
		<Suspense>
			<Layout {...props}>
				<main>
					{isBot ? (
						<ServerHomePage {...props} />
					) : (
						<ClientHomePage {...props} />
					)}
				</main>
			</Layout>
		</Suspense>
	)
}

export default Home

export { getServerSideProps }
